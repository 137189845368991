<!-- =========================================================================================
    File Name: VxTour.vue
    Description: Tour Component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <v-tour
    :name="nameTour"
    :steps="steps"
    class="custom--tour"
  >
    <template slot-scope="tour">
      <transition name="fade">
        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
        <v-step
          v-for="(step, index) of tour.steps"
          v-if="tour.currentStep === index"
          :key="index"
          :step="step"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :class="step.class"
        >
          <div slot="content">
            <div v-html="steps[index].content" />
            <div v-if="steps[index].hint">
              <hr>
              <div>
                <feather-icon
                  class="mr-25"
                  size="15"
                  icon="AlertCircleIcon"
                />
                <span class="size12 text-white pr-2">{{ steps[index].hint }}</span>
              </div>
            </div>
          </div>
          <div
            slot="actions"
            class="tour-actions d-flex justify-content-between"
          >
            <!-- Skip Button -->
            <!-- <b-button
              v-if="tour.currentStep != tour.steps.length - 1"
              size="sm"
              variant="outline-primary"
              class="btn-tour-skip mr-1"
              @click="tour.stop"
            >
              <span class="mr-25 align-middle">Skip</span>
              <feather-icon
                icon="XIcon"
                size="12"
              />
            </b-button> -->

            <!-- Previous Button -->
            <!-- <b-button
              v-if="tour.currentStep"
              size="sm"
              variant="outline-primary mr-1"
              @click="tour.previousStep"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle">Previous</span>
            </b-button> -->

            <!-- Next Button -->
            <b-button
              v-if="tour.currentStep != tour.steps.length - 1 && steps[index].isHideButton == false"
              class="btn-tour-next custom--button__next mt-1"
              @click="tour.nextStep"
            >
              <span class="mr-25 align-middle">{{ steps[index].buttonNext || 'Next' }}</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />
            </b-button>

            <!-- Finish Button -->
            <b-button
              v-if="tour.currentStep == tour.steps.length - 1 && steps[index].isHideButton == false && step.tutorialFlag"
              class="btn-tour-next custom--button__next mt-1"
              @click="finishTutorial()"
            >
              <span class="mr-25 align-middle">{{ steps[index].buttonFinish || 'Saya Mengerti' }}</span>
              <feather-icon
                icon="CheckCircleIcon"
                size="12"
              />
            </b-button>
            <b-button
              v-else-if="tour.currentStep == tour.steps.length - 1 && steps[index].isHideButton == false"
              class="btn-tour-next custom--button__next mt-1"
              @click="tour.stop"
            >
              <span class="mr-25 align-middle">{{ steps[index].buttonFinish || 'Saya Mengerti' }}</span>
              <feather-icon
                icon="CheckCircleIcon"
                size="12"
              />
            </b-button>
          </div>

        </v-step>
        <!-- eslint-enable vue/no-use-v-if-with-v-for -->
      </transition>
    </template>
  </v-tour>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'VxTour',
  components: {
    BButton,
  },
  props: {
    nameTour: {
      required: true,
      type: String,
    },
    steps: {
      required: true,
      type: Array,
    },
    tutorialFlag: {
      type: Boolean,
    },
  },
  methods: {
    finishTutorial() {
      this.$store.dispatch('tutorial/postData')
        .then(result => {
          this.$tours.cashierTour7.stop()
          const userData = JSON.stringify(result.data.data)
          this.$store.commit('cashier/setUserData', result.data.data)
          localStorage.setItem('userData', userData)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err.response.data)
        })
    },
  },
}
</script>

<style lang="scss">
.custom--tour {
  .v-step[x-placement^=left] {
    .v-step__arrow {
      left: -0.5rem;
      transform: rotate(180deg);
    }
  }
  .v-step[x-placement^=bottom].custom__tour--add-customer {
    left: -120px !important;
  }
  .v-step[x-placement^=bottom].custom__tour--choose-customer {
    left: -120px !important;
  }
}
</style>

<style lang="scss" scoped>
.btn-tour-next.custom--button__next {
  background-color: #4A5A71 !important;
  border-color: 1px solid #4A5A71 !important;
  border-radius: 6px;
}
</style>
